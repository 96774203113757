<!--三项工程政策侧边栏-->
<template>
  <div class="notice">
    <div class="notice-title">
      <span class="fl">三农政策</span>
      <span class="fr"
        ><router-link to="/List?infoType=249253465337925&type=三农政策"
          >更多<i class="el-icon-arrow-right"></i></router-link
      ></span>
    </div>
    <div class="notice-list">
      <div class="notice-item" v-for="item in tudiPageData" :key="item.id"
      @click="$router.push(`/detail?id=${item.id}&type=三农政策&oParams=${JSON.stringify(tudiInfoparams)}`)"
      >
        <el-image
          style="width: 100%; height: 100%"
          :src="item.photoUrls && item.photoUrls.split(',')[0]"
        ></el-image>
        <div class="name ellipsis">{{ item.title }}</div>
        <!-- <div class="notice-name">土地政策</div> -->
        <!-- <el-carousel
          trigger="click"
          height="150px"
          :autoplay="false"
          arrow="never"
        >
          <el-carousel-item v-for="item in tudiPageData" :key="item.id">
            <div class="notice-info">
              <div class="notice-pic">
                <router-link
                  :to="`/detail?id=${
                    item.id
                  }&type=三农政策&oParams=${JSON.stringify(tudiInfoparams)}`"
                >
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls"
                  ></el-image>
                </router-link>
              </div>
              <div class="notice-text ellipsis">关键词： {{ item.title }}</div>
            </div>
          </el-carousel-item>
        </el-carousel> -->
      </div>
      <!-- <div class="notice-item">
        <div class="notice-name">补贴政策</div>
        <el-carousel
          trigger="click"
          height="150px"
          :autoplay="false"
          arrow="never"
        >
          <el-carousel-item v-for="item in butiePageData" :key="item.id">
            <div class="notice-info">
              <div class="notice-pic">
                <router-link
                  :to="`/detail?id=${
                    item.id
                  }&type=三农政策&oParams=${JSON.stringify(butieInfoparams)}`"
                >
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls"
                  ></el-image>
                </router-link>
              </div>
              <div class="notice-text ellipsis">关键词：{{ item.title }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="notice-item">
        <div class="notice-name">农业政策</div>
        <el-carousel
          trigger="click"
          height="150px"
          :autoplay="false"
          arrow="never"
        >
          <el-carousel-item v-for="item in nongyePageData" :key="item.id">
            <router-link to="/">
              <div class="notice-info">
                <div class="notice-pic">
                  <router-link
                    :to="`/detail?id=${
                      item.id
                    }&type=三农政策&oParams=${JSON.stringify(
                      nongyeInfoparams
                    )}`"
                  >
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.photoUrls"
                    ></el-image>
                  </router-link>
                </div>
                <div class="notice-text ellipsis">
                  关键词：{{ item.title }}
                </div>
              </div>
            </router-link>
          </el-carousel-item>
        </el-carousel>
      </div> -->
    </div>
  </div>
</template>

<script>
import { GetInformationPage } from "@/api/home.js";
export default {
    data() {
        return {
            pageData: {},
            tudiInfoparams: {
                currentPage: 1,
                filter: {
                  infoType: "249253465337925",
                },
                pageSize: 5,
            },
            nongyeInfoparams: {
                currentPage: 1,
                filter: {
                infoType: "249253465337925",
                secondInfoType: "农业政策",
                },
                pageSize: 5,
            },
            butieInfoparams: {
                currentPage: 1,
                filter: {
                infoType: "249253465337925",
                secondInfoType: "补贴政策",
                },
                pageSize: 5,
            },
            tudiPageData: {},
            nongyePageData: {},
            butiePageData: {},
        };
    },
    methods: {
        gettudiInfoList() {
            GetInformationPage(this.tudiInfoparams).then((res) => {
                this.tudiPageData = res.data.data.list;
            });
        },
        getnongyeInfoList() {
            GetInformationPage(this.nongyeInfoparams).then((res) => {
                this.nongyePageData = res.data.data.list;
            });
        },
        getbutieInfoList() {
            GetInformationPage(this.butieInfoparams).then((res) => {
                this.butiePageData = res.data.data.list;
            });
        },
    },
    mounted() {
        this.gettudiInfoList();
        // this.getnongyeInfoList();
        // this.getbutieInfoList();
    }
}
</script>

<style scoped>
.notice-item{
  height: 150px;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
}
.name{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);

}
.notice >>> .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.notice-item >>> .el-carousel__indicators--horizontal {
  bottom: 18px;
  left: auto;
  right: 10px;
  transform: translateX(0);
}
.pagination {
  text-align: center;
}
.notice-text {
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.notice-info {
  position: relative;
  height: 150px;
}
.notice-pic {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.notice-name {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
}
.notice-list {
  border: 1px solid #ccc;
  padding: 10px;
}
.notice-title a {
  color: #fff;
}
.notice-title {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
  background: #de0049;
  color: #fff;
  padding: 0 10px;
}
.notice {
  /* width: 370px; */
}
</style>
