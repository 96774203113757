<!--共享农庄-->
<template>
  <div class="beautiful-vlliage w-1400">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/xiangninghui' }"
          >乡宁荟</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/xiangninghui/sharehouse' }"
          >共享农庄</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          params.filter.businessType
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="25">
      <el-col :span="18">
        <div class="search-box">
          <div class="clearfix">
            <label class="fl">经营类型</label>
            <i class="fl" style="margin-right:20px">:</i>
            <div
              class="fl"
              :class="{ isactive: !params.filter.businessType }"
              @click="
                params.filter.businessType = '';
                handleQuery();
              "
            >
              不限
            </div>
            <ul class="clearfix fl search-list">
              <li
                v-for="item in businessTypeList"
                class="fl classify-item"
                :key="item"
                :class="{ isactive: params.filter.businessType == item }"
                @click="
                  params.filter.businessType = item;
                  handleQuery();
                "
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="clearfix">
            <label class="fl">位置</label>
            <i class="fl" style="margin-right: 20px">:</i>
            <div
              class="fl"
              :class="{ isactive: !params.filter.regionCode }"
              @click="
                params.filter.regionCode = '';
                handleQuery();
              "
            >
              不限
            </div>
            <ul class="clearfix fl search-list">
              <li
                v-for="item in region"
                class="fl classify-item"
                :key="item.code"
                :class="{ isactive: params.filter.regionCode == item.code }"
                @click="
                  params.filter.regionCode = item.code;
                  handleQuery();
                "
              >
                {{ item.name }}
              </li>
            </ul>
            <!-- <span v-for="(item,index) in region" :class="{isactive:index==0}" :key="item">{{item}}</span> -->
          </div>
          <div class="clearfix">
            <label class="fl">特色</label>
            <i class="fl" style="margin-right: 20px">:</i>
            <div
              class="fl"
              :class="{ isactive: !params.filter.suitablePeople }"
              @click="
                params.filter.suitablePeople = '';
                handleQuery();
              "
            >
              不限
            </div>
            <ul class="clearfix fl search-list">
              <li
                v-for="item in feature"
                class="fl classify-item"
                :key="item"
                :class="{ isactive: params.filter.suitablePeople == item }"
                @click="
                  params.filter.suitablePeople = item;
                  handleQuery();
                "
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="clearfix">
            <label class="fl">游玩项目</label>
            <i class="fl" style="margin-right: 20px">:</i>
            <div
              class="fl"
              :class="{ isactive: !params.filter.playItems }"
              @click="
                params.filter.playItems = '';
                handleQuery();
              "
            >
              不限
            </div>
            <ul class="clearfix fl search-list">
              <li
                v-for="item in plays"
                class="fl classify-item"
                :key="item"
                :class="{ isactive: params.filter.playItems == item }"
                @click="
                  params.filter.playItems = item;
                  handleQuery();
                "
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="clearfix">
            <label class="fl">人均消费</label>
            <i class="fl" style="margin-right: 20px">:</i>
            <div
              class="fl"
              :class="{ isactive: (params.filter.pcConsumptionMin == 0 && params.filter.pcConsumptionMax == 0) }"
              @click="
                params.filter.pcConsumptionMin = 0;
                params.filter.pcConsumptionMax = 0;
                handleQuery();
              "
            >
              不限
            </div>
            <ul class="clearfix fl search-list">
              <li
                v-for="item in price"
                class="fl classify-item"
                :key="item.valueMin + '~' + item.valueMax"
                :class="{ isactive: params.filter.pcConsumptionMin == item.valueMin && params.filter.pcConsumptionMax == item.valueMax }"
                @click="
                  params.filter.pcConsumptionMin = item.valueMin;
                  params.filter.pcConsumptionMax = item.valueMax;
                  handleQuery();
                "
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
          <div class="clearfix">
            <label class="fl">条件</label>
            <i class="fl" style="margin-right: 20px">:</i>
            <div class="isactive fl" @click="clearParams">清空筛选项</div>
          </div>
          <div class="villiage-list">
            <div class="v-list-sort">
              <span
                :class="{ isactive: params.orderName == 'Sort' }"
                @click="
                  params.orderName = 'Sort'; //排序
                  params.orderDesc = false; //增序
                  handleQuery();
                "
                >推荐排序</span
              >
              <span
                :class="{ isactive: params.orderName == '' }"
                @click="
                  params.orderName = ''; //默认按ModifiedTime
                  params.orderDesc = true; //降序
                  handleQuery();
                "
                >最新<i class="el-icon-sort"></i
              ></span>
            </div>
          </div>
        </div>
        <div v-if="pageData.list && pageData.list.length > 0">
          <div
            class="v-list-item clearfix"
            v-for="item in pageData.list"
            :key="item.id"
          >
            <div class="v-item-pic fl">
              <router-link
                :to="`/sharehousedetail?id=${item.id}&businessType=${params.filter.businessType}`"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.photoUrls && item.photoUrls.split(',')[0]"
                ></el-image>
              </router-link>
            </div>
            <div class="v-item-info fl">
              <div class="v-item-name">{{ item.name }}</div>
              <div class="v-item-address">{{ item.address }}</div>
              <div class="v-item-phone">联系电话：{{ item.telephone }}</div>
              <div class="v-item-detail">
                {{ item.introduction }}
              </div>
              <div class="v-item-shoppingtime">
                <p>
                  开业时间：{{
                    item.openingDate && item.openingDate.substr(0, 10)
                  }}
                </p>
                <p>营业时间：{{ item.openingTime }}</p>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 500px; border-top: 1px solid #ddd" v-else>
          <el-empty description="暂无数据"></el-empty>
        </div>
        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next,jumper"
          :total="pageData.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
      <el-col :span="6">
        <sxgczc></sxgczc>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { GetSharedFarmPage ,GetInformationPage } from "@/api/home.js";
import sxgczc from '@/components/xiangninghui/sanxiangzhengce'
export default {
  components:{sxgczc},
  data() {
    return {
      region: [
        // { name: "全部", code: 0 },
        { name: "大通县", code: 630121 },
        { name: "湟源县", code: 630123 },
        { name: "湟中区", code: 630122 },
        { name: "城东区", code: 630102 },
        { name: "城中区", code: 630103 },
        { name: "城西区", code: 630104 },
        { name: "城北区", code: 630105 },
      ],
      feature: ["家庭游", "亲子游", "情侣游", "团体游", "其他"],
      plays: [
        "农家饭",
        "烧烤",
        "采摘",
        "溜冰",
        "垂钓",
        "漂流",
        "温泉",
        "赏花",
        "喝茶",
        "棋牌",
        "扩展",
        "划船",
        "会议",
        "挖野菜",
        "骑马",
        "健身",
        "禅修",
        "篝火",
        "真人CS",
        "游泳",
        "攀岩",
        "卡拉ok",
        "滑雪",
        "其他",
      ],
      businessTypeList: [
        "特色农庄",
        "精品民宿",
        "农家乐",
        "采摘园",
        "生态园",
        "农家住宿",
        "其他",
      ],
      price: [ //0 - 忽略
        { title: "100元以下", valueMin: 0, valueMax: 100 },
        { title: "100~200元", valueMin: 100, valueMax: 200 },
        { title: "200~300元", valueMin: 200, valueMax: 300 },
        { title: "300~500元", valueMin: 300, valueMax: 500 },
        { title: "500元以上", valueMin: 500, valueMax: 0 },
      ],
      params: {
        currentPage: 1,
        pageSize: 10,
        orderName: "Sort", //排序字段名称
        orderDesc: false, //true: 降序，false: 增序
        filter: {
          regionCode: "",
          suitablePeople: "",
          playItems: "",
          businessType: "",
          pcConsumptionMin: 0,
          pcConsumptionMax: 0,
          name: "",
        },
      },
      pageData: {},
    };
  },
  mounted() {
    this.params.filter.businessType = this.$route.query.businessType;
    this.handleQuery();
  },
  methods: {
    getPageList() {
      GetSharedFarmPage(this.params).then((res) => {
        console.log(res.data);
        this.pageData = res.data.data;
      });
    },
    //查询
    handleQuery() {
      this.params.currentPage = 1;
      this.getPageList();
    },
    //分页
    handleCurrentChange(page) {
      this.params.currentPage = page;
      this.getPageList();
    },
    clearParams() {
      this.params = {
        currentPage: 1,
        pageSize: 10,
        orderName: this.params.orderName,
        orderDesc: this.params.orderDesc,
        filter: {
          regionCode: "",
          suitablePeople: "",
          playItems: "",
          businessType: "",
          pcConsumptionMin: 0,
          pcConsumptionMax: 0,
          name: "",
        },
      };
      this.handleQuery();
    },
  },
};
</script>
<style scoped>
.notice >>> .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.notice-item >>> .el-carousel__indicators--horizontal {
  bottom: 18px;
  left: auto;
  right: 10px;
  transform: translateX(0);
}
.pagination {
  text-align: center;
}
.notice-text {
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.notice-info {
  position: relative;
  height: 150px;
}
.notice-pic {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.notice-name {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
}
.notice-list {
  border: 1px solid #ccc;
  padding: 10px;
}
.notice-title a {
  color: #fff;
}
.notice-title {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  background: #de0049;
  color: #fff;
  padding: 0 10px;
}
.notice {
  /* width: 370px; */
}
.v-item-shoppingtime {
  position: absolute;
  top: 14px;
  right: 10px;
  background: #f9773b;
  padding: 12px;
  font-size: 14px;
  color: #fff;
}
.v-item-detail {
  font-size: 14px;
  line-height: 22px;
  text-indent: 24px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.v-item-address,
.v-item-phone {
  font-size: 14px;
  height: 24px;
  line-height: 24px;
}
.v-item-name {
  font-size: 18px;
  font-weight: 600;
  height: 36px;
  line-height: 36px;
}
.v-item-info {
  width: calc(100% - 310px);
  margin-left: 20px;
  position: relative;
  font-family: "新宋体";
}
.v-item-pic {
  width: 290px;
  height: 180px;
  overflow: hidden;
}
.v-list-item {
  height: 200px;
  overflow: hidden;
  padding: 10px;
  background: #eee;
  margin-bottom: 20px;
}
.v-list-sort {
}
.villiage-list {
  /* width: 1000px; */
}
.v-list-sort span {
  font-size: 16px;
  font-weight: 800;
  height: 36px;
  line-height: 36px;
  margin-right: 30px;
  cursor: pointer;
}
.search-list {
  max-width: 620px;
}
.search-box span.isactive,
.v-list-sort span.isactive,
.isactive {
  color: #4283d2;
  cursor: pointer;
}
.search-box {
  margin-top: 20px;
  padding: 5px;
}
.search-box div {
  font-size: 18px;
  font-weight: 700;
  /* height: 40px; */
  line-height: 40px;
}
.search-box label > span {
}
.search-box label {
  width: 100px;
  text-align: justify;
  text-align-last: justify;
}
.classify-item {
  padding: 0px 10px;
  font-size: 18px;
  /* font-weight: 600; */
  cursor: pointer;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>